import { IGlobalStore } from 'src/types/store'
import {
  TStateCommunications,
  createStateSagaCommunications,
} from 'src/helpers/redux'

import { IModuleConfig } from './index'
import { Actions } from './actions'
import * as smartListTypes from 'src/services/storage/project/scheme/smart-list/types'
import * as schemaService from 'src/services/storage/project/scheme/types'
import { getSession } from 'src/services/session'

export * from 'src/services/storage/project/scheme/types'

export type FieldValue = string | number | null | undefined

export interface IListItem extends schemaService.IGetObjectsData {
  commentsCount?: number
}

export interface IUpdateObjectItem {
  uniqId: string
  data: { key: keyof IListItem; value: number }
}

export interface IColumn {
  identifier: string
  objectValueKey: string
  slug: string
  enable: boolean
  width?: number
  priority: {
    index: number
    static: boolean
  }
  schema?: schemaService.ISchema
}

export interface IStateData {
  smartList: {
    itemDefault?: smartListTypes.IGetSmartListItemData
    item?: smartListTypes.IGetSmartListItemData
    list: {
      items: smartListTypes.IGetSmartListData
      nextCursor?: string | null
    }
  }

  objects: {
    item?: IListItem
    list: {
      items: IListItem[]
      previousCursor: string | null
      nextCursor: string | null
    }
  }

  fieldsGroups: {
    list: {
      items: schemaService.IFieldsGroupData[]
      previousCursor: string | null
      nextCursor: string | null
    }
  }
  table: {
    columns: IColumn[]
    filters: { [key: string]: unknown }
  }
}

export interface IState {
  data: IStateData
  communication: TStateCommunications<Actions>
}
const getStorageState = (key: string) => {
  try {
    return JSON.parse(localStorage.getItem(key) || '{}')
  } catch (error) {
    return {}
  }
}
export const getInitialData = (config: IModuleConfig): IStateData => {
  const stateData = getStorageState(config.prefix)

  const session = getSession()
  const isCurrent = (item: IModuleConfig) =>
    item._prefix === config._prefix && item.schemeName === config.schemeName
  const storageData = (session.storage || []).find(isCurrent)

  return {
    smartList: {
      item: undefined,
      itemDefault: storageData?.smartListItemDefault || undefined,
      list: {
        items: storageData?.smartListsItems || [],
        nextCursor: undefined,
      },
    },
    objects: {
      item: undefined,
      list: {
        items: storageData?.objectItems || [],
        previousCursor: null,
        nextCursor: null,
      },
    },
    fieldsGroups: {
      list: {
        items: storageData?.fieldsGroupsItems || [],
        previousCursor: null,
        nextCursor: null,
      },
    },

    table: storageData?.table || {
      columns: [],
      filters: {},
    },
    ...stateData,
  }
}

export const getInitialState = (
  config: IModuleConfig,
  actions: Actions
): IState => ({
  data: getInitialData(config),
  communication: createStateSagaCommunications(actions),
})

export type TGlobalStateWithService = IGlobalStore & {
  [key: string]: IState
}
