/* eslint-disable @typescript-eslint/no-unused-vars */
import * as SchemeServiceTypes from 'src/services/storage/project/scheme/types'
import * as storageObjectsState from 'src/store/modules/storage/objects/state'
import { camelCase } from 'camel-case'
import {
  ISchema,
  CommunicationTypes,
} from 'src/services/storage/project/scheme/types'
import { title } from 'process'

export const getObjectValueKey = (schema: ISchema) =>
  camelCase(schema.identifier)

export const isSchemaLinkable = (schema: ISchema) =>
  (
    [
      CommunicationTypes.SITE,
      CommunicationTypes.FACEBOOK,
      CommunicationTypes.TWITTER,
      CommunicationTypes.LINKEDIN,
    ] as string[]
  ).includes(schema.communicationType || '')

export const isSchemaClalable = (schema: ISchema) =>
  (
    [
      CommunicationTypes.PHONE,
      CommunicationTypes.TELEGRAM,
      CommunicationTypes.VIBER,
      CommunicationTypes.WHATSAPP,
    ] as string[]
  ).includes(schema.communicationType || '')

export const getCommunicationTypeGeneral = (schema: ISchema) => {
  if (!schema.communicationType) return 'text'
  if (schema.communicationType === 'email') return 'email'
  if (isSchemaLinkable(schema)) return 'link'
  if (isSchemaClalable(schema)) return 'phone'

  return 'text'
}
export const createSchemeFieldTypeSlug = (
  schema: SchemeServiceTypes.ISchemaProps
): string =>
  [
    schema.valueType,
    schema.isMultivalue ? 'multivalue' : '',
    schema.visualStyle,
    schema.mediaType,
    schema.communicationType,
  ]
    .filter((type) => type)
    .join('.')

interface ISetupColumnsProps {
  schemaName: string
  fieldsGroups: SchemeServiceTypes.IFieldsGroupData[]
  schemas: SchemeServiceTypes.ISchemaInfo[]
}

export const setupColumns = (
  props: ISetupColumnsProps
): storageObjectsState.IColumn[] => {
  const { schemaName, fieldsGroups, schemas } = props

  const baseRelation = schemas.find((scheme) =>
    scheme.children?.find?.((child) => child.identifier === schemaName)
  )
  const currentSchema = schemas.find(
    (scheme) => scheme.identifier === schemaName
  )

  const fields = fieldsGroups.reduce((previous, current) => {
    if (current.schemas) {
      previous.push(...current.schemas)
    }

    return previous
  }, [] as SchemeServiceTypes.ISchema[])

  const columns = fields.map((schema, index) => ({
    identifier: schema.identifier,
    slug: createSchemeFieldTypeSlug(schema),
    objectValueKey: getObjectValueKey(schema),
    enable: true,
    priority: {
      index: index + 2,
      static: false,
    },
    schema,
  }))

  const tableColumns = [
    {
      identifier: schemaName,
      slug: schemaName,
      objectValueKey: schemaName,
      enable: true,
      title: currentSchema?.title,
      priority: {
        index: 0,
        static: true,
      },
    },
    ...columns,
  ]

  if (baseRelation) {
    tableColumns.push({
      identifier: 'parentObjectUniqId',
      slug: 'parentObjectUniqId',
      objectValueKey: 'parentObjectUniqId',
      enable: true,
      title: baseRelation?.title,
      schema: {
        identifier: 'parentObjectUniqId',
        valueType: 'relationship',
        title: baseRelation?.title,
        visualStyle: 'dropdown',
        relationshipObjectSchemeId: baseRelation.id,
      } as SchemeServiceTypes.ISchema,
      priority: {
        index: 1,
        static: false,
      },
    })
  }

  return tableColumns
}
