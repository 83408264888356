import React from 'react'
import { useLayoutContext } from 'src/layouts/VerticalLayout'

import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import IconButton from '@mui/material/IconButton'

import UserIcon from 'src/components/ui/UserIcon'
import ProfileMenu from './components/ProfileDropdown'
import CommunicationWidgetMini from 'src/components/widgets/CommunicationWidget/Components/CommunicationWidgetMini'
import SearchModal from './components/SearchModal'
// import Badge from '@mui/material/Badge'
// import NotificationsMenu from './components/NotificationMenu'
// import { useActivityActions, useActivitySelect } from 'src/hooks/useActivities'

const GlobalSearchComponent = () => {
  const [openDialog, setOpenDialog] = React.useState(false)
  // const [anchorNotif, setAnchorNotif] = React.useState<null | HTMLElement>(null)

  // const getUserActivityCount = useActivityActions.getUserActivityCount()
  // const getUserActivities = useActivityActions.getUserActivities()

  // const userActivityCount = useActivitySelect.userActivityCount()

  // React.useEffect(() => {
  //   getUserActivityCount()
  // }, [])

  // React.useEffect(() => {
  //   getUserActivities()
  // }, [])

  const { setNavHandle } = useLayoutContext()

  const openDialogHandle = () => {
    setOpenDialog(true)
  }

  const manageNavigationHandle = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    setNavHandle?.()
  }

  // const handleOpenNotifications = (e: React.MouseEvent<HTMLButtonElement>) => {
  //   e.stopPropagation()
  //   setAnchorNotif(e.currentTarget)
  // }

  // const handleCloseNotifMenu = () => {
  //   setAnchorNotif(null)
  // }

  return (
    <>
      <Stack direction="row" alignItems="center" spacing={3}>
        <Stack
          flex={1}
          py={2}
          px={6}
          elevation={10}
          component={Paper}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ cursor: 'pointer' }}
          onClick={openDialogHandle}
        >
          <Stack direction="row" alignItems="center" spacing={2}>
            <IconButton size="small" onClick={manageNavigationHandle}>
              <UserIcon icon="fluent:navigation-16-filled" />
            </IconButton>
            <UserIcon icon="mdi:search" />
          </Stack>

          <Stack direction="row" alignItems="center" columnGap={6}>
            {/* <IconButton
              onClick={handleOpenNotifications}
              sx={{ color: 'grey.900' }}
            >
              <Badge badgeContent={userActivityCount} color="primary">
                <UserIcon icon="material-symbols:notifications" />
              </Badge>
            </IconButton> */}

            <ProfileMenu />
          </Stack>
        </Stack>
        <CommunicationWidgetMini />
      </Stack>
      {/* {Boolean(anchorNotif) && (
        <NotificationsMenu
          anchor={anchorNotif}
          handleClose={handleCloseNotifMenu}
        />
      )} */}
      {openDialog && (
        <SearchModal
          openDialog={openDialog}
          onClose={() => setOpenDialog(false)}
        />
      )}
    </>
  )
}

export default GlobalSearchComponent
