export * from './objects/types'
export * from './fields/types'
export * from './fields-groups/types'
export * from './comments/types'

export enum ValidationTypes {
  ADDRESS = 'address',
  EMAIL = 'email',
  PHONE = 'phone',
  SITE = 'site',
  DATETIME_FUTURE = 'datetime_future',
  DATETIME_PAST = 'datetime_past',
  REGEXP = 'regexp',
}

export enum ValueTypes {
  STRING = 'string',
  TEXT = 'text',
  INTEGER = 'integer',
  FLOAT = 'float',
  BOOLEAN = 'boolean',
  DATE = 'date',
  TIME = 'time',
  DATETIME = 'datetime',
  RELATIONSHIP = 'relationship',
  MEDIA = 'media',
  FORMULA = 'formula',
  USER = 'user',
  CURRENCY = 'currency',
}

export enum MediaTypes {
  IMAGE = 'image',
  VIDEO = 'video',
  FILE = 'file',
}

export enum CommunicationTypes {
  EMAIL = 'email',
  PHONE = 'phone',
  WHATSAPP = 'whatsapp',
  VIBER = 'viber',
  TELEGRAM = 'telegram',
  FACEBOOK = 'facebook',
  TWITTER = 'twitter',
  SITE = 'site',
  LINKEDIN = 'linkedin',
}

export enum VisualStyles {
  DROPDOWN = 'dropdown',
  RADIO = 'radio',
  CHECKBOX = 'checkbox',
  FLAT_LIST = 'flat_list',
}
export enum EShortPurpose {
  icon = 'icon',
  primary = 'primary',
  secondary = 'secondary',
}
export interface ISchema {
  communicationType: `${CommunicationTypes}` | null
  createdAt: string
  fieldsGroupId: number
  formula: string | null
  hasChoices: boolean
  id: number | string
  identifier: string
  isDeleted: boolean
  isHidden: boolean
  isMultivalue: boolean
  isRequired: boolean
  mediaType: `${MediaTypes}` | null
  objectSchemeId: number
  placeholder: string | null
  readOnly: boolean
  relationshipObjectSchemeId: number | null
  showEmpty: boolean | null
  sortIndex: number
  title: string
  shortPurpose?: `${EShortPurpose}` | null
  tooltip: string | null
  updatedAt: string | null
  validation: `${ValidationTypes}` | null
  validationRegexp: string | null
  valueType: `${ValueTypes}` | null
  visualStyle: `${VisualStyles}` | null
  choices?: {
    createdAt: string
    fieldSchemeId: number
    id: number
    index: number
    isDeleted: boolean
    updatedAt: string
    value: string
  }[]
}

export interface IFieldsOption {
  title: string
  slug: string
  icon: string
  schema: ISchemaProps
}

export type ISchemaProps = { [key in keyof ISchema]?: ISchema[key] }

export interface IGetSchemasParams {
  limit?: number
}
export interface IPutSchemasParams {
  id: number
  title: string
  identifier: string
  parentObjectSchemeIdentifier?: string
}
export type IPutSchemasData = ISchemaInfo

export interface IPostSchemasParams {
  id?: number
  title: string
  identifier: string
  parentObjectSchemeIdentifier?: string
}
export type IPostSchemasData = ISchemaInfo

export interface IDeleteSchemasParams {
  identifier: string
}
export type IDeleteSchemasData = ISchemaInfo

export interface ISchemaInfo {
  id: number
  identifier: string
  title: string
  children?: ISchemaInfo[]
}

export type IGetSchemasData = ISchemaInfo[]
