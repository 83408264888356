import { createSelector } from 'reselect'
import { createCommunicationSelectors } from 'src/helpers/redux'
import { ECommState } from 'src/types/redux'
import { IModuleConfig } from './index'
import { IState, TGlobalStateWithService, ISchema } from './state'
import { Actions } from './actions'

export const createSelectors = (config: IModuleConfig, actions: Actions) => {
  const { prefix } = config

  const selectData = (state: TGlobalStateWithService): IState['data'] =>
    state[prefix].data

  const selectCommunication = (
    state: TGlobalStateWithService
  ): IState['communication'] => state[prefix].communication

  const communications = createCommunicationSelectors<
    TGlobalStateWithService,
    IState['communication'],
    typeof actions
  >(selectCommunication, actions)

  const selectFieldsShemes = createSelector(
    selectData,
    (state) =>
      state.fieldsGroups.list.items.reduce((previous, current) => {
        if (current.schemas) {
          previous.push(...current.schemas)
        }

        return previous
      }, [] as ISchema[]),
    {
      memoizeOptions: (a, b) => a.fieldsGroups === b.fieldsGroups,
    }
  )
  const selectors = {
    objectsList: createSelector(selectData, (state) => state.objects.list),
    isObjectsListEmpty: createSelector(
      [selectData, selectCommunication],
      (state, status) =>
        state.objects.list.items.length === 0 &&
        status.getObjectsList.state === ECommState.fulfilled
    ),
    isObjectsListNextLoad: createSelector(
      [selectData, selectCommunication],
      (state, status) =>
        state.objects.list.nextCursor &&
        status.getObjectsList.state === ECommState.fulfilled
    ),
    isObjectsListPrevLoad: createSelector(
      [selectData, selectCommunication],
      (state, status) =>
        state.objects.list.previousCursor &&
        status.getObjectsList.state === ECommState.fulfilled
    ),
    objectsItem: createSelector(selectData, (state) => state.objects.item),
    fieldsGroupsList: createSelector(
      selectData,
      (state) => state.fieldsGroups.list
    ),

    fieldsShemes: selectFieldsShemes,
    primaryFieldScheme: createSelector(selectFieldsShemes, (fields) =>
      fields.find((field) => field.shortPurpose === 'primary')
    ),
    tableColumns: createSelector(selectData, (state) => state.table.columns),
    tableColumnsEnabled: createSelector(selectData, (state) =>
      state.table.columns
        .filter((column) => column.enable)
        .sort((a, b) => a.priority.index - b.priority.index)
    ),
    smartListItems: createSelector(
      selectData,
      (state) => state.smartList.list.items
    ),
    smartListNextCursor: createSelector(
      selectData,
      (state) => state.smartList.list.nextCursor
    ),
    smartListItem: createSelector(selectData, (state) => state.smartList.item),
    smartListItemDefault: createSelector(
      selectData,
      (state) => state.smartList.itemDefault
    ),
    data: createSelector(selectData, (state) => state),
  }

  return {
    selectData,
    selectCommunication,
    communications,
    selectors,
  }
}

export type Selectors = ReturnType<typeof createSelectors>['selectors']
