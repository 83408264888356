import React, { createContext, useContext } from 'react'
import Fab from '@mui/material/Fab'
import { styled } from '@mui/material/styles'
import Box, { BoxProps } from '@mui/material/Box'
import Icon from 'src/components/ui/icon'
import { LayoutProps } from 'src/layouts/types'
import Navigation from './components/vertical/navigation'
import ScrollToTop from 'src/components/ui/scroll-to-top'
import Stack from '@mui/material/Stack'
import GlobalSearchComponent from './components/GlobalSearchComponent'

const LayoutContext = createContext<{
  navState: boolean
  setNavHandle?: () => void
}>({ navState: false })

export const useLayoutContext = () => useContext(LayoutContext)

const VerticalLayoutWrapper = styled('div')({
  height: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
})

const MainContentWrapper = styled(Box)<BoxProps>({
  flexGrow: 1,
  minWidth: 0,
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
})

const ContentWrapper = styled('main')(({ theme }) => ({
  flexGrow: 1,
  width: '100%',
  paddingTop: theme.spacing(6),
  transition: 'padding .25s ease-in-out',
}))

const VerticalLayout = (props: LayoutProps) => {
  const { children, scrollToTop, contentHeightFixed } = props
  const [navState, setNavState] = React.useState(true)

  const setNavHandle = () => {
    setNavState((prev) => !prev)
  }

  return (
    <>
      <LayoutContext.Provider value={{ navState, setNavHandle }}>
        <VerticalLayoutWrapper className="layout-wrapper">
          <Navigation />

          <MainContentWrapper
            className="layout-content-wrapper"
            sx={{
              ...(contentHeightFixed && { maxHeight: '100vh' }),
              transition: '0.3s',

              maxWidth: navState ? 'calc(100% - 255px)' : '100%',
            }}
          >
            <Stack px={6} pt={6}>
              <GlobalSearchComponent />
            </Stack>
            <ContentWrapper className="layout-page-content">
              {children}
            </ContentWrapper>
          </MainContentWrapper>
        </VerticalLayoutWrapper>
      </LayoutContext.Provider>

      {scrollToTop ? (
        scrollToTop(props)
      ) : (
        <ScrollToTop className="mui-fixed">
          <Fab color="primary" size="small" aria-label="scroll back to top">
            <Icon icon="tabler:arrow-up" />
          </Fab>
        </ScrollToTop>
      )}
    </>
  )
}

export default VerticalLayout
