import { http } from './http'
import { Device } from '@twilio/voice-sdk'
import store from 'src/store'
import { setters } from 'src/store/modules/communication'
import { CallState, IOutgoingCall } from 'src/store/modules/communication/types'
import * as communication from 'src/store/modules/communication'

export const getTwilioToken = () => http.get<{ token: string }>('/twilio/token')

export interface IGetCallHistory {
  cursor?: string
  subscriberStorageId?: string
}

export const getCallHistory = (params: IGetCallHistory) =>
  http.get('/calls', { params: { ...params, subscriberType: 'phone' } })

let twilioToken: string
let device: Device
let call: any

const resetInterface = () => {
  store.dispatch(setters.resetUserState())
}

export async function startupClient() {
  try {
    const dataToken = await getTwilioToken()

    // @ts-ignore
    twilioToken = dataToken.token

    intitializeDevice()
  } catch (err) {
    store.dispatch(communication.actions.setTwillioState(false))
  }
}

function intitializeDevice() {
  device = new Device(twilioToken)
  addDeviceListeners()

  device.register()
}

export function addDeviceListeners() {
  device.on('error', function () {
    store.dispatch(communication.actions.setTwillioState(false))
  })

  device.on('unregistered', () => {
    store.dispatch(communication.actions.setTwillioState(false))
  })

  device.on('registered', () =>
    store.dispatch(communication.actions.setTwillioState(true))
  )

  device.on('incoming', (callInstance) => {
    store.dispatch(setters.setCallState(CallState.INCOMING))
    const fromWhoIncoming = JSON.parse(
      callInstance.customParameters.get('CusmanSubscriberJson') || {}
    ).storage_object

    store.dispatch(
      setters.setUserCall({
        number: callInstance.parameters.From,
        from: callInstance.parameters.From,
        name: `${fromWhoIncoming.primary} ${fromWhoIncoming.secondary}`,
        scheme: fromWhoIncoming.object_scheme_identifier,
        uniqId: fromWhoIncoming.uniq_id,
        avatarUrl: fromWhoIncoming.icon,
      })
    )

    call = callInstance

    call.on('cancel', () => resetInterface())

    call.on('disconnect', () => resetInterface())

    call.on('reject', () => resetInterface())
  })
}

export const incomingCallAccept = () => {
  store.dispatch(setters.setCallState(CallState.ANSWERED))
  call.accept()
}

export const rejectIncomingCall = () => {
  resetInterface()
  call?.reject()
}

export const disconnectHandler = () => {
  resetInterface()

  call?.disconnect()
}

export const makeOutgoingCall = async ({ toWhom, userId }: IOutgoingCall) => {
  if (!userId) return

  const { number } = toWhom

  const params = {
    To: number,
    CusmanAccountId: userId,
  }

  if (device) {
    call = await device.connect({ params })

    store.dispatch(setters.setUserCall(toWhom))
    store.dispatch(setters.setCallState(CallState.ANSWERED))

    call.on('disconnect', () => resetInterface())

    call.on('cancel', () => resetInterface())
  }
}
