/* eslint-disable @typescript-eslint/no-unused-vars */
import Drawer from '@mui/material/Drawer'
import { styled } from '@mui/material/styles'
import { SwipeableDrawerProps } from '@mui/material/SwipeableDrawer'
import { useLayoutContext } from 'src/layouts/VerticalLayout'

const StyledDrawer = styled(Drawer)<SwipeableDrawerProps>({
  overflowX: 'hidden',
  transition: 'width .25s ease-in-out',
  '& ul': {
    listStyle: 'none',
  },
  '& .MuiListItem-gutters': {
    paddingLeft: 4,
    paddingRight: 4,
  },
})

const DrawerComponent = (props: { children: React.ReactNode }) => {
  const DesktopDrawerProps = {
    onOpen: () => null,
    onClose: () => null,
  }

  const { navState } = useLayoutContext()

  return (
    <StyledDrawer
      transitionDuration={300}
      open={navState}
      variant="persistent"
      {...DesktopDrawerProps}
      PaperProps={{
        sx: {
          backgroundColor: 'navigationMenu.background',
          color: 'navigationMenu.textColor',
          borderRight: (theme) => `${1}px solid ${theme.palette.divider}`,
          width: 255,
          pb: 12,
        },
      }}
    >
      {props.children}
    </StyledDrawer>
  )
}

export default DrawerComponent
