import React from 'react'
import { useNavigate, useLocation, NavigateFunction } from 'react-router-dom'
//@ts-ignore
import memoize from 'memoize'

const decodeSearchMemo = memoize((search: string) => {
  try {
    const query = JSON.parse(atob(search.replace('?', '')) || '{}')

    return query
  } catch (e) {
    console.error(e)

    return {}
  }
})
export const decodeSearch = () => decodeSearchMemo(window.location.search)

class QuerySearch {
  navigate: NavigateFunction

  constructor(navigate: NavigateFunction) {
    this.navigate = navigate
  }
  setQuery = <query>(toBeUpdatedQuery: query, replace = false) => {
    const mergedQuery = { ...decodeSearch(), ...toBeUpdatedQuery } as query
    const mergedQueryString = JSON.stringify(mergedQuery)
    this.navigate(
      {
        pathname: window.location.pathname,
        search: btoa(mergedQueryString),
      },
      { replace }
    )
  }

  setPathQuery = <query>(
    path: string,
    toBeUpdatedQuery: query,
    replace = false
  ) => {
    const mergedQuery = { ...decodeSearch(), ...toBeUpdatedQuery } as query
    const mergedQueryString = JSON.stringify(mergedQuery)

    this.navigate(
      {
        pathname: path,
        search: btoa(mergedQueryString),
      },
      { replace }
    )
  }

  removeQuery = <query>(toBeRemovedQuery: (keyof query)[], replace = false) => {
    const mergedQuery = { ...decodeSearch() }

    toBeRemovedQuery.forEach((prop) => {
      delete mergedQuery[prop]
    })

    const mergedQueryString = JSON.stringify(mergedQuery)

    this.navigate(
      {
        pathname: window.location.pathname,
        search: btoa(mergedQueryString),
      },
      { replace }
    )
  }
}

export const useSearchQuery = <Query extends Record<any, any>>() => {
  const location = useLocation()
  const navigate = useNavigate()
  const decode = () => decodeSearchMemo(window.location.search) as Query

  const query = React.useMemo(() => {
    const queryData = decode()

    return queryData
  }, [location.search])

  const querySearch = React.useMemo(() => {
    return new QuerySearch(navigate)
  }, [])

  return {
    query,
    location,
    navigate,
    decode,
    setPathQuery: querySearch.setPathQuery<Query>,
    setQuery: querySearch.setQuery<Query>,
    removeQuery: querySearch.removeQuery<Query>,
  }
}
